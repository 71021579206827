import Alpine from 'alpinejs';
// import Toaster from '../../../vendor/masmerise/livewire-toaster/resources/js';
import {auth, mySize, sidebar} from './data';

import collapse from '@alpinejs/collapse'

Alpine.plugin(collapse)

Alpine.data('sidebar', sidebar)
Alpine.data('auth', auth)
Alpine.data('mySize', mySize)

Alpine.store('sidebar', {
    on: false,
    id: 'sidebar',
})

window.Alpine = Alpine;

export {Alpine}
