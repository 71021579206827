import anime from 'animejs/lib/anime.es.js';

if (document.querySelectorAll('section.product_container .product_gallery').length > 0) {
	anime({
		targets: 'section.product_container .product_gallery',
		left: [-100, 0],
		opacity: [0, 1],
		easing: 'easeInOutQuad',
		duration: 500,
		complete: function (anim) {
			anim.animatables[0].target.classList.add('animated');
		}
	})
}
if (document.querySelectorAll('section.product_container .product_details').length > 0) {
	anime({
		targets: 'section.product_container .product_details',
		right: [-100, 0],
		opacity: [0, 1],
		easing: 'easeInOutQuad',
		duration: 500,
		complete: function (anim) {
			anim.animatables[0].target.classList.add('animated');
		}
	})
}
const additional_products_ob = new IntersectionObserver((entries) => {
	entries.forEach((entry) => {
		if (entry.isIntersecting && !entry.target.classList.contains('animated')) {
			anime({
				targets: '.slider_additional_products',
				bottom: [-100, 0],
				opacity: [0, 1],
				easing: 'easeInOutQuad',
				duration: 500,
				complete: function (anim) {
					anim.animatables[0].target.classList.add('animated');
				}
			})
		}
	})
})
const products_page_slider_additional_products = document.querySelectorAll('.slider_additional_products');
products_page_slider_additional_products.forEach((el) => additional_products_ob.observe(el))

const recommend_products_ob = new IntersectionObserver((entries) => {
	entries.forEach((entry) => {
		if (entry.isIntersecting && !entry.target.classList.contains('animated')) {
			anime({
				targets: '.slider_recommend_products',
				bottom: [-100, 0],
				opacity: [0, 1],
				easing: 'easeInOutQuad',
				duration: 500,
				complete: function (anim) {
					anim.animatables[0].target.classList.add('animated');
				}
			})
		}
	})
})
const products_page_slider_recommend_products = document.querySelectorAll('.slider_recommend_products');
products_page_slider_recommend_products.forEach((el) => recommend_products_ob.observe(el))

function collapse_my_size(e) {
	console.log(e.target);
	// let div_el = e.target.closest('div');
	// let svg = div_el.querySelector('svg');
	// let section = e.target.closest('li');
	let options_list = document.querySelector('.dropdown');
	console.log(options_list)

	let height = options_list.clientHeight;
	if (options_list.classList.contains('closed')) {
		options_list.style.height = 'auto';
		height = options_list.clientHeight;
		options_list.style.height = 0;
	}
	// if (!options_list.classList.contains('closed')) {
	// 	options_list.style.height = options_list.clientHeight + 'px';
	// 	// height = options_list.clientHeight;
	// 	// options_list.style.height = 0;
	// }
	anime({
		targets: options_list,
		height: (!options_list.classList.contains('closed')) ? [height, 0] : [0, height],
		opacity: (!options_list.classList.contains('closed')) ? [1, 0] : [0, 1],
		easing: 'easeInOutQuad',
		duration: 300,
		complete: function (anim) {
			anim.animatables[0].target.classList.toggle('closed');
			// if (!anim.animatables[0].target.classList.contains('closed')) {
			// 	anim.animatables[0].target.style.height = 'auto';
			// }
		}
	})
	// anime({
	// 	targets: svg,
	// 	rotate: (options_list.classList.contains('closed')) ? [0, 90] : [90, 0],
	// 	easing: 'easeInOutQuad',
	// 	duration: 300,
	// })

}

window.collapse_my_size = (e) => collapse_my_size(e);