import './bootstrap';
import {Alpine} from './alpine/index';
import 'livewire-sortable'
import './sliders'
import './animation/home_page'
import './animation/product_page'
import './animation/category_page'

import.meta.glob([
	'../images/**',
]);
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
function setWindowHeight(){
	var windowHeight = window.innerHeight;
	document.body.style.height = windowHeight + "px";
	// console.log(document.body.style.height);
	let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
	document.documentElement.style.setProperty('--vh', `${vh}px`);
}
window.addEventListener("resize",setWindowHeight,false);


Alpine.start();

$(function() {

	var url = window.location.href;

	if (url.search('attr') != -1) {
		$('html, body').animate({
			scrollTop: $('.product_grid_control').offset().top
		}, 500);
	}

});
var video =  document.querySelectorAll('.video_bg video')

//add source to video tag
function addSourceToVideo(element, src) {
	console.log('qwe');
	var source = document.createElement('source');
	source.src = src;
	source.type = 'video/mp4';
	console.log(src);
	element.appendChild(source);

}

//determine screen size and select mobile or desktop vid
function whichSizeVideo(element, src) {
	var windowWidth = window.innerWidth ? window.innerWidth : $(window).width();
	console.log(windowWidth);
	if (windowWidth > 767 ) {
		addSourceToVideo( element, src.dataset.desktopVid);
	} else {
		addSourceToVideo(element, src.dataset.mobileVid);
	}
}

//init only if page has videos
function videoSize() {
	if (video !== undefined) {
		video.forEach(function(element, index) {
			whichSizeVideo(
				element, //element
				element  //src locations
			);
		});
	}
}
videoSize();

window.open_filter_sidebar = (e) => open_filter_sidebar(e);
window.hide_filter_sidebar = (e) => hide_filter_sidebar(e);

function open_filter_sidebar(e){
	document.querySelector(".sidebar_filter_overlay").classList.add("show_tablet");
	document.querySelector(".sidebar_filter").classList.add("show_tablet");
	document.querySelector("body").classList.add("block_scroll");
}
function hide_filter_sidebar(e){
	document.querySelector(".sidebar_filter_overlay").classList.remove("show_tablet");
	document.querySelector(".sidebar_filter").classList.remove("show_tablet");
	document.querySelector("body").classList.remove("block_scroll");
}
// .products_grid_wrapper .product_grid_control .selected_filters_container .container_title.show_tablet

window.submit_header_search_form = (e) => submit_header_search_form(e);

function submit_header_search_form(e){
	var form = document.getElementById("header_search_form");
	form.submit();
}
