import anime from "animejs/lib/anime.es.js";

export const sidebar = (id, on = false) => ({
    id: id,
    on: on,

    init() {
        this.$watch('on', (on) => {
            this.$store.sidebar.id = this.id
            this.$store.sidebar.on = on

            // const padding = window.innerWidth - document.body.offsetWidth + 'px'
            // document.body.style.paddingRight = on ? padding : 0
            // document.body.style.overflow = on ? 'hidden' : 'visible'

            this.animate(on)
        })

        if (on) {
            this.$refs.sidebar.style.transform = 'translateX(0%)'
        }
    },

    toggle() {
        this.on = !this.on
    },
    open() {
        this.on = true
    },
    close() {
        this.on = false
    },

    animate(on) {
        anime({
            targets: this.$refs.sidebar, easing: 'linear',
            duration: 100, translateX: on ? '0%' : '100%'
        });
    },

    sidebarBind: {
        ['@sidebar.window']({detail}) {
            if (this.id === detail) {
                this.toggle()
            }
        },
    },
})

export const auth = (prop = null) => ({

    login: false,
    register: false,
    forgot: false,

    init() {
        this.toggle(prop)
    },

    toggle(prop) {
        switch (prop) {
            case 'login':
                this.loginToggle();
                break;
            case 'register':
                this.registerToggle();
                break;
            case 'forgot':
                this.forgotToggle();
                break
        }
    },

    loginToggle() {
        this.login = true
        this.register = false
        this.forgot = false
    },
    registerToggle() {
        this.register = true
        this.login = false
        this.forgot = false
    },
    forgotToggle() {
        this.forgot = true
        this.register = false
        this.login = false
    },

    authBind: {
        ['@auth.window']({detail}) {
            this.toggle(detail)
            this.$dispatch('sidebar', 'login')
        },
    },
})

export const mySize = (prop = null) => ({
    showMySize: false,
    length: null,
    size: null,

    calcSize() {
        if(typeof this.length == "number"){
            this.size = Math.round((this.length / 3.14))
        } else {
            this.size = null
        }
        
    }
})